<template>
  <div>
    <b-card>
      <TitleTable titleTable="Open Box" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect v-model="perPage" :options="perPageOptions" :clearable="true" class="w-25" />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(facilities)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.facilities.avatar" />
            </template>
            <b-link :to="{
              name: 'admin-facilities-view',
              params: { id: data.item.facilities.id },
            }">
              <span class="
                  font-weight-bold
                  d-block
                  text-nowrap
                  d-flex
                  flex-column
                  align-items-start
                ">
                <span>
                  {{ data.item.facilities.name }}
                </span>
                <span>
                  {{ data.item.facilities.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span @click="viewBox(data.item.id)">
              <i class="fas fa-eye text-success cursor-pointer" v-b-tooltip.hover.top="'View Box Data'"></i>
            </span>
            <span :id="`invoice-row-${data.item.id}-send-icon`" @click="closeBox(data.item.id)">
              <i class="fas fa-ban text-danger mx-1 cursor-pointer" v-b-tooltip.hover.top="'Close Box'"></i>
            </span>
          </div>
        </template>
      </b-table>
    </b-card>
    <ToastNotification ref="toast" />
    <viewBox :seeBox.sync="seeBox" :box="box" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BMedia,
  BLink,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

import ToastNotification from "@/components/ToastNotification";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import viewBox from "./viewBox.vue"

import axiosC from "@/core/services/api/cash-account";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BMedia,
    BLink,
    VBTooltip,
    ToastNotification,
    TitleTable,
    GoBack,
    viewBox
  },
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: "facilities", label: "Facility", sortable: true },

        { key: "frontdesks", label: "Frontdesk", sortable: true },
        // A regular column
        { key: "current_balance", label: "current balance" },
        { key: "current_descriptive" },
        { key: "income" },
        { key: "opening" },
        "Actions",
      ],
      items: null,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      box: null,
      seeBox: false
    };
  },
  mounted() {
    this.getCash();
  },
  methods: {
    getCash() {
      axiosC.openBoxes(this.perPage).then(({ data: { data } }) => {
        this.items = data;
      });
    },
    viewBox(id) {
      axiosC.getBoxById(id).then(({ registro }) => {
        this.box = registro
        this.seeBox = true
      });
    },
    closeBox(id) {
      this.$router.push({
        name: "cash-closing-by-id", params: {
          id: id
        }
      })
    }
  },
};
</script>

<style></style>