import axios from "axios";
import router from "@/router";
import ability from "@/core/utils/acl/ability";
import { initialAbility } from "@/core/utils/acl/config.js";

const API_EYECLINIC_FORM = process.env.VUE_APP_RUTA_API || ""
const URL_CLOUDINARY = process.env.VUE_APP_URL_CLOUDINARY || ""

export const userAxios = axios.create({
    baseURL: `${API_EYECLINIC_FORM}/api`,
})

/* Creating a new axios instance with a different baseURL. */
export const cloudinaryAxios = axios.create({
    baseURL: URL_CLOUDINARY,
})

userAxios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('accessToken') || null
    const facility = localStorage.getItem('facility') || null
    config.headers.Facility = facility
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    config.headers['Access-Control-Allow-Origin'] = '*',
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'

    return config;
});

userAxios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem("userData");

        // Reset ability
        ability.update(initialAbility);

        localStorage.removeItem('Facility')
        localStorage.removeItem('setFacilityOptions')

        // Redirect to login page
        router.push({ name: "login" }).catch(() => { });
    }
    return Promise.reject(error)
})
