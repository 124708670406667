<template>
  <div></div>
</template>

<script>
import ToastificationContent from "@/core/plugins/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
  },
  methods: {
    danger(text = '', title = 'Error') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'fas fa-times-circle',
            text,
            variant: 'danger',
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    success(text = '', title = 'Success') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'far fa-check-circle',
            text,
            variant: 'success',
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    warning(text = '', title = 'Warning') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'fas fa-wrench',
            text,
            variant: 'warning',
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
  },
};
</script>