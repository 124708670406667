<template>
    <b-modal :visible="seeBox" @change="(val) => $emit('update:seeBox', val)" size="xl" :title="`${title}`">
        <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
            show-empty empty-text="No matching records found">
            <template #cell(frontdesks)="data">
                {{ data.item.frontdesks.name }} {{ data.item.frontdesks.lastname }}
            </template>
            <template #cell(opening)="data">
                {{ new Date(data.item.opening).toLocaleDateString() }}
            </template>
            <template #cell(updated_at)="data">
                {{ new Date(data.item.updated_at).toLocaleDateString() }}
            </template>
        </b-table>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';


export default {
    components: {
        BModal,
        BButton,

    },
    data() {
        return {
            fields: [
                "id",
                "frontdesks",
                "income",
                "expenses",
                "current_balance",
                "current_descriptive",
                "descriptive_expenses",
                "descriptive_income",
                "opening",
                "updated_at",
            ],
            title: "",
            items: []
        }
    },
    model: {
        prop: "seeBox",
        event: "update:seeBox",
    },
    props: {
        seeBox: {
            type: Boolean,
            default: false,
            required: true
        },
        box: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    watch: {
        box() {
            if(this.box) {
                this.title = this.box.cash.facilities.name
                this.items = this.box.cash.cashes
            }
        }
    }
}
</script>