import { userAxios } from "../index";

const cashGlobalList = async () => {
  try {
    return await userAxios
      .get(`cashControl/clinicCash`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashView = async (id) => {
  try {
    return await userAxios.get(`cashControl/${id}`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalClose = async (data) => {
  try {
    return await userAxios
      .post(`cashControl/clinicCash/closing`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalDaily = async () => {
  try {
    return await userAxios.get(`cashControl/admin`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistory = async (id, perPage) => {
  try {
    return await userAxios
      .get(`cashControl/admin/clinic/${id}/${perPage}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistoryPaginated = async (id, perPage, page) => {
  try {
    return await userAxios
      .get(`cashControl/admin/clinic/${id}/${perPage}?page=${page}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const attachFile = async (data) => {
  try {
    return await userAxios
      .post(`cashControl/clinicCash/attach`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const ailmentPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`previusMedicalHistory/all/${perPage}?page=${page}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const ailmentFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`previusMedicalHistory/search/${perPage}?page=${page}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const openBoxes = async (perPage = 10) => {
  try {
    return await userAxios
      .get(`cashControl/cashesOpening/${perPage}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const getBoxById = async (id) => {
  try {
    return await userAxios
      .get(`cashControl/clinicCash/${id}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const closeCashById = async (id, data) => {
  try {
    return await userAxios
      .post(`cashControl/clinicCash/closing/${id}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export default {
  openBoxes,
  getBoxById,
  closeCashById,
  cashGlobalList,
  cashView,
  cashGlobalClose,
  cashGlobalDaily,
  cashGlobalHistory,
  attachFile,
  ailmentPagination,
  ailmentFilterPagination,
  cashGlobalHistoryPaginated,
};
